<template>
  <b-container fluid>
    <span class="d-none">{{total}}</span>
    <h5 class="text-secondary text-left font-weight-bold mt-3">
      {{ checkLists.self.name }}
    </h5>
    <hr class="bg-primary">
    <b-table-simple hover small striped stacked="xl" responsive="lg"
                    v-for="checkList in checkLists.checkLists" :key="checkList.jsonApi.id">
      <b-thead head-variant="dark">
        <b-tr>
          <b-th class="text-left">{{checkList.name}}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th :stacked-heading="checkList.name" class="text-white bg-dark"/>
        </b-tr>
        <!--Tabla de Campos -->
        <b-table-simple hover small striped stacked="xl" responsive="lg" caption-top
                        v-for="field in checkList.checkFields" :key="field.id">
          <!--Nombre del Campo -->
          <b-thead>
            <b-tr>
              <b-th v-if="field.properties !== null"
                    class="text-center text-dark" :colspan="2 + field.properties.length + 1">
                {{field.name}}
              </b-th>
              <b-th v-else colspan="2" class="text-center text-dark">
                {{field.name}}
              </b-th>
            </b-tr>
          </b-thead>
          <!--Nombre del Campo -->
          <b-tbody>
            <b-tr>
              <b-th :stacked-heading="field.name" class="text-center text-dark"/>
              <!-- Pripiedades-->
              <template v-if="field.properties !== undefined && field.properties !== null">
                <b-td class="text-center" v-for="value in field.properties" :key="value.key">
                  <a-checkbox checked disabled v-if="value.boolean === 'true' || value.boolean === '1' || value.boolean === true " >
                    {{value.value}}
                  </a-checkbox>
                  <a-checkbox  disabled v-else>
                    {{value.value}}
                  </a-checkbox>
                </b-td>
              </template>
              <!-- Pripiedades-->

              <!-- Opciones-->
              <b-td class="text-center" >
                <a-radio-group @change="onChangeSwitch($event,checkList.key,field.key)"
                               v-decorator="['opciones',{rules: [{ required: true, message: 'Esta campo es obligatorio.' }]}]"
                               button-style="solid">
                  <a-radio-button value="SI">SI</a-radio-button>
                  <a-radio-button value="NO">NO</a-radio-button>
                  <a-radio-button value="NA">NA</a-radio-button>
                </a-radio-group>
              </b-td>
              <!--Opciones-->
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <!--Tabla de Campos -->
      </b-tbody>
    </b-table-simple>
    <hr class="bg-primary">
    <slot name="form"/>
  </b-container>
</template>

<script>
export default {
  name: 'checkList',
  props: {
    checkLists: {
      type: [Object],
      required: true,
    },
  },
  data() {
    return {
      checkListForm: {},
    }
  },
  computed: {
    total() {
      let total = 0
      const checkList = this.checkLists.checkLists
      Object.entries(checkList).forEach(([key, value]) => {
        total += Object.keys(value.checkFields).length
      })
      this.$emit('totalFields', total)
      return total
    },
  },
  methods: {
    onChangeSwitch(e, checkList, field) {
      const value = e.target.value
      const key = `${checkList}_${field}`
      // Escribe el objeto con lógica negativa
      if (Object.prototype.hasOwnProperty.call(this.checkListForm, key)) {
        // Eliminar de checkListForm
        delete this.checkListForm[key]
      }
      this.checkListForm[key] = { field, value }

      this.$emit('updateCheckListForm', this.checkListForm)
    },
  },
}
</script>

<style scoped>
.border-w-3 {
  border-width:3px !important;
}
.border-w-5 {
  border-width:5px !important;
}
</style>
