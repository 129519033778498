<template>
  <div>
    <div class="air__utils__heading">
      <h5>Recepción del Servicio</h5>
    </div>
    <my-breadcrumbs :routes="routes" class="my-4"/>
    <!-- NAV TOOLBAR -->
    <servicePlanningNavToolbar v-if="folio !== '0'" :serviceId="folio"/>
    <!-- NAV TOOLBAR -->
    <div class="row">
      <div class="col">
        <div class="mb-5">
          <a-tabs defaultActiveKey="2" type="card" size="large" @change="tabChange">
            <a-tab-pane key="2">
              <span slot="tab">
                <a-icon type="car" />
                Transporte
              </span>
              <!--     INSPECCION   -->
              <div class="card">
                <div class="card-body text-center">
                  <transport-service-form :serviceId="servicePlanningId"/>
                </div>
              </div>
              <!--     INSPECCION   -->
            </a-tab-pane>
            <a-tab-pane key="3">
              <span slot="tab">
                <a-icon type="file-search" />
                Vigilancia
              </span>
              <!--     INSPECCION   -->
              <div class="card">
                <div class="card-body text-center">
                  <h5 class="text-secondary text-left text-capitalize font-weight-bold mt-2">Información Logística de Transporte </h5>
                  <p class="text-left small">
                    Pulse el botón seleccionar para realizar el Check List de Vigilancia a un Servicio de Transporte
                  </p>
                  <hr class="bg-primary">
                  <servicePlanningCarrierTable :serviceId="folio" :tableObject="tableCarrier"
                                               @SelectCarrier="SelectCarrier" :view="['select','weighing']"/>
                  <b-modal :title="`CheckList de  ${servicePlanningCarrierID}`" ref="checklist-Form" size="xl" centered>
                    <template v-if="showSurveillanceCheck">
                      <b-alert show variant="warning" class="text-center text-capitalize">
                        Este servico ya cuenta con un checkList registrado, consulte su reporte
                      </b-alert>
                      <div class="row justify-content-center my-4">
                        <responsive-button text="Impresión de Reporte" icon="printer-fill"
                                           pill variant="secondary" size="sm" responsive="md"
                                           :to="{name:'check_list_report', params: {checkListFormId: checkListFormId}}"
                        />
                      </div>
                    </template>
                    <template  v-else>
                      <check-list v-if="!LOCK_SERVICE"
                          :checkLists="getCheckStructure(receptionState.surveillanceCheck)"
                          @updateCheckListForm="surveillanceCheckListForm = $event"
                          @totalFields="surveillanceTotalFields = $event">
                        <b-container fluid slot="form">
                          <a-form-model layout="horizontal" :model="surveillanceForm" :rules="surveillanceRules" ref="surveillance-Form">
                            <a-row align="middle" justify="center">
                              <a-col :sm="{ span: 24, }" :md="{ span:12}" >
                                <a-form-model-item label="Nombre del Vigilante" class="mx-1" ref="inspector_user_id" prop="inspector_user_id">
                                  <a-select v-model="surveillanceForm.inspector_user_id"
                                            placeholder="Seleccionar"
                                            :disabled="infoView"
                                            show-search
                                            :filter-option="filterOption"
                                  >
                                    <a-select-option  v-for="user in surveillanceInspectors" :key="user.jsonApi.id" :value="user.jsonApi.id" >
                                      {{user.name}}
                                    </a-select-option>
                                  </a-select>
                                </a-form-model-item>
                              </a-col>
                              <a-col :sm="{ span: 24, }" :md="{ span:6}" ref="date" prop="date">
                                <a-form-model-item label="Fecha de Inspección" class="mx-1">
                                  <a-date-picker format="DD/MM/YYYY" v-model="surveillanceForm.date" />
                                </a-form-model-item>
                              </a-col>
                              <a-col :sm="{ span: 24, }" :md="{ span:6}">
                                <a-form-model-item label="Hora Final de Inspección" class="mx-1"  ref="final_hour" prop="final_hour">
                                  <a-time-picker v-model="surveillanceForm.final_hour" format="HH:mm" />
                                </a-form-model-item>
                              </a-col>
                            </a-row>
                          </a-form-model>
                          <my-spinner :load="surveillanceLoad"/>
                          <div class="row justify-content-center my-4" v-show="!surveillanceLoad">
                            <div class="col-2">
                              <responsive-button  variant="success" pill text="Guardar" @ClickEvent="saveCheckListForm"
                                                  responsive="md"  icon="archive-fill" size="sm"/>
                            </div>
                          </div>
                        </b-container>
                      </check-list>
                      <div v-else>
                        <b-alert show variant="warning" class="text-center text-capitalize">
                          Este servico ha sido concluido sin haber relizado el check list
                        </b-alert>
                      </div>
                    </template>

                    <template v-slot:modal-footer>
                      <simple-modal-footer @ClickEvent="CloseCheckListModal" />
                    </template>
                  </b-modal>
                </div>
              </div>
              <!--     INSPECCION   -->
            </a-tab-pane>
            <a-tab-pane key="4">
              <span slot="tab">
                <a-icon type="experiment" />
                Laboratorio
              </span>
              <div class="card">
                <div class="card-body text-center">
                  <h5 class="text-secondary text-left text-capitalize font-weight-bold mt-2">Información Logística de Transporte </h5>
                  <p class="text-left small">
                    Pulse el botón seleccionar para realizar el Check List de Laboratorio a un Servicio de Transporte
                  </p>
                  <hr class="bg-primary">
                  <servicePlanningCarrierTable :serviceId="folio" :tableObject="tableCarrier"
                                               @SelectCarrier="SelectCarrierLab" :view="['select']"/>

                  <b-modal :title="`CheckList de  ${servicePlanningCarrierLabID}`" ref="checklist-LabForm" size="xl" centered>
                    <template v-if="showLaboratoryCheck">
                      <b-alert show variant="warning" class="text-center text-capitalize">
                        Este servico ya cuenta con un checkList registrado, consulte su reporte
                      </b-alert>
                      <div class="row justify-content-center my-4">
                        <responsive-button text="Impresión de Reporte" icon="printer-fill"
                                           pill variant="secondary" size="sm" responsive="md"
                                           :to="{name:'check_list_report', params: {checkListFormId: labCheckListFormId}}"
                        />
                      </div>
                    </template>
                    <template  v-else>
                      <check-list v-if="!LOCK_SERVICE"
                                  :checkLists="getCheckStructure(receptionState.laboratoryCheck)" @updateCheckListForm="laboratoryCheckListForm = $event"
                                  @totalFields="laboratoryTotalFields = $event">
                        <b-container fluid slot="form">
                          <a-form-model layout="horizontal" :model="laboratoryForm" :rules="laboratoryRules" ref="laboratory-Form">
                            <a-row align="middle" justify="center">
                              <a-col :span="24">
                                <a-form-model-item label="Observaciones" class="mx-1" ref="comments" prop="comments">
                                  <a-textarea v-model="laboratoryForm.comments" placeholder="Observaciones" :rows="4" allow-clear/>
                                </a-form-model-item>
                              </a-col>
                            </a-row>
                            <hr class="bg-primary">
                            <a-row align="middle" justify="center">
                              <a-col :sm="{ span: 24, }" :md="{ span:12}" >
                                <a-form-model-item label="Nombre del Técnico de Laboratorio" class="mx-1" ref="inspector_user_id" prop="inspector_user_id">
                                  <a-select v-model="laboratoryForm.inspector_user_id"
                                            placeholder="Seleccionar"
                                            :disabled="infoView"
                                            show-search
                                            :filter-option="filterOption"
                                  >
                                    <a-select-option  v-for="user in surveillanceInspectors" :key="user.jsonApi.id" :value="user.jsonApi.id" >
                                      {{user.name}}
                                    </a-select-option>
                                  </a-select>
                                </a-form-model-item>
                              </a-col>
                              <a-col :sm="{ span: 24, }" :md="{ span:6}">
                                <a-form-model-item label="Fecha" class="mx-1" ref="date" prop="date">
                                  <a-date-picker format="DD/MM/YYYY" v-model="laboratoryForm.date" />
                                </a-form-model-item>
                              </a-col>
                              <a-col :sm="{ span: 24, }" :md="{ span:6}">
                                <a-form-model-item label="Hora Final de Inspección" class="mx-1" ref="final_hour" prop="final_hour">
                                  <a-time-picker v-model="laboratoryForm.final_hour" format="HH:mm" />
                                </a-form-model-item>
                              </a-col>
                            </a-row>
                            <a-row align="middle" justify="center">
                              <a-col :sm="{ span: 24, }" :md="{ span:12}" >
                                <a-form-model-item label="Nombre del Cordinador de Operaciones Geocycle" class="mx-1" ref="autho_user_id" prop="autho_user_id">
                                  <a-select v-model="laboratoryForm.autho_user_id"
                                            placeholder="Seleccionar"
                                            :disabled="infoView"
                                            show-search
                                            :filter-option="filterOption"
                                  >
                                    <a-select-option  v-for="user in surveillanceInspectors" :key="user.jsonApi.id" :value="user.jsonApi.id" >
                                      {{user.name}}
                                    </a-select-option>
                                  </a-select>
                                </a-form-model-item>
                              </a-col>
                            </a-row>
                            <!-- BOTONES -->
                          </a-form-model>
                          <my-spinner :load="laboratoryLoad"/>
                          <div class="row justify-content-center my-4" v-show="!laboratoryLoad">
                            <div class="col-2">
                              <responsive-button  variant="success" pill text="Guardar" @ClickEvent="saveLabCheckListForm"
                                                  responsive="md"  icon="archive-fill" size="sm"/>
                            </div>
                          </div>
                        </b-container>
                      </check-list>
                      <div v-else>
                        <b-alert show variant="warning" class="text-center text-capitalize">
                          Este servico ha sido concluido sin haber relizado el check list
                        </b-alert>
                      </div>
                    </template>
                    <template v-slot:modal-footer>
                      <simple-modal-footer @ClickEvent="CloseCheckListModalLab" />
                    </template>
                  </b-modal>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="5">
              <span slot="tab">
                <a-icon type="warning" />
                Incidencias
              </span>
              <div class="card">
                <div class="card-body text-center">
                  <incidentForm :serviceId="folio"/>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import transportServiceForm from '@/views/geocycle/service_plannings/components/serviceForms/transportServiceForm'
import checkList from '@/views/geocycle/service_plannings/components/checkList'
import servicePlanningCarrierTable from '../components/servicePlanningCarrierTable'
import servicePlanningNavToolbar from '../components/servicePlanningNavToolbar'
import incidentForm from '../../incidents/create_incidents/createIncidentTable'
import moment from 'moment'
import 'moment/locale/es'
import { notification, Modal } from 'ant-design-vue'
import { mapActions, mapGetters } from 'vuex'
import { getGeneralMixin } from '@/mixins/getGeneralMixin'
import { apiRestMixin } from '@/mixins/apiRestMixin'
import api from '@/services/axios/index'
export default {
  name: 'reception',
  components: {
    checkList,
    incidentForm,
    transportServiceForm,
    servicePlanningNavToolbar,
    servicePlanningCarrierTable,
  },
  props: {
    infoView: {
      default: false,
      type: [Boolean],
    },
  },
  mixins: [getGeneralMixin, apiRestMixin],
  data() {
    return {
      // Contants
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Planeación de Servicios',
          aIcon: '',
          name: 'service_plannings',
        },
        {
          breadcrumbName: 'Recepción del Servicio',
          aIcon: '',
        },
      ],
      folio: this.$route.params.folio,
      service_folio: this.$route.params.folio.toString(),
      //  ----- Forms -----//
      // Vigilancia
      serviceDetailModalShow: false,
      serviceDetailModalService: {},
      surveillanceForm: {
        inspector_user_id: undefined,
        date: moment(),
        final_hour: moment(),
        comments: '',
      },
      surveillanceRules: {
        inspector_user_id: [
          { required: true, message: 'El Nombre del inspector obligatorio.', trigger: 'blur' },
        ],
        date: [
          { required: true, message: 'La Fecha de Inspección es obligatoria.', trigger: 'blur' },
        ],
        final_hour: [
          { required: true, message: 'El Hora de Inspección es obligatoria.', trigger: 'blur' },
        ],
      },
      surveillanceLoad: false,
      servicePlanningCarrierID: '',
      surveillanceChecks: {},
      showSurveillanceCheck: true,
      // Laboratorio
      laboratoryForm: {
        inspector_user_id: undefined,
        autho_user_id: undefined,
        date: moment(),
        final_hour: moment(),
        comments: '',
      },
      laboratoryRules: {
        inspector_user_id: [
          { required: true, message: 'El Nombre del inspector obligatorio.', trigger: 'blur' },
        ],
        autho_user_id: [
          { required: true, message: 'El Nombre del Autorizador obligatorio.', trigger: 'blur' },
        ],
        date: [
          { required: true, message: 'La Fecha de Inspección es obligatoria.', trigger: 'blur' },
        ],
        final_hour: [
          { required: true, message: 'El Hora de Inspección es obligatoria.', trigger: 'blur' },
        ],
      },
      laboratoryLoad: false,
      showLaboratoryCheck: true,
      servicePlanningCarrierLabID: '',
      // Laboratorio
      incidenceForm: {
        name: '',
        date: moment(),
        hour: moment(),
        comments: '',
      },
      incidenceRules: {
        name: [
          { required: true, message: 'El campo Nombre es obligatorio.', trigger: 'blur' },
        ],
      },
      incidenceLoad: false,
      // -----  Check List  -----//
      // Vigilancia
      surveillanceInspectors: [],
      surveillanceCheckListForm: {},
      surveillanceTotalFields: 0,
      // Laboratorio
      laboratoryCheckListForm: {},
      laboratoryTotalFields: 0,
      // ServicePlanningCarriersTable
      tableCarrier: {
        data: [],
        loading: false,
        perPageValue: 15,
        currentPageValue: 1,
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 30,
          lastPage: 0,
        },
      },
      // ServicePlanningCarriersTable
      // Reemplazo de vuex para checkList
      checkListVigilancia: {},
      checkListLaboratorio: {},
      // ID de checkListFormId para consultar reporte
      checkListFormId: '0',
      labCheckListFormId: '0',
    }
  },
  computed: {
    ...mapGetters('receptionServicePlannings', ['receptionState', 'getServiceID', 'getCheckStructure']),
    ...mapGetters('servicePlannings', ['LOCK_SERVICE']),
    servicePlanningId() {
      return this.$store.state.servicePlannings.servicePlanningId
    },
  },
  methods: {
    ...mapActions('receptionServicePlannings', ['SET_CHECKSTRUCTURE', 'SET_SERVICEID']),
    // Tab Control
    async tabChange(key) {
      if (key === '3' || key === '4') {
        await this.GetCarriers()
      }
    },
    // Get ServicePlanningCarriers
    async GetCarriers(paginationEvent = {}) {
      const params = {
        'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.tableCarrier.pagination.current}` : `${this.tableCarrier.currentPageValue}`,
        'page[size]': `${this.tableCarrier.perPageValue}`,
        'filter[service]': this.servicePlanningId,
        include: 'drivers,trucks.logisticsShipmentTypes,trucks.carrierCompanies,weightRegistries,checkListForms.checks',
      }
      await this.getGeneralAntTable(paginationEvent, 'servicePlanningsCarriers', params, this.tableCarrier)
    },
    // Get Catalogs
    // ==
    async getSurveillanceInspectors() {
      this.generalResponse = []
      const params = {
        sort: 'name',
      }
      await this.getResourceData(params, 'users')
      this.surveillanceInspectors = this.generalResponse
      this.generalResponse = []
    },
    // Get Catalogs

    // Set CheckList
    async SelectCarrier(data) {
      // Set the servicePlanningCarrierID
      this.$refs['checklist-Form'].show()
      this.servicePlanningCarrierID = data.jsonApi.id
      await this.GET_CHECK(this.receptionState.surveillanceCheck)
      this.showSurveillanceCheck = false
      // Verificar si existe el checkListForm correspondiente al servicePlanningCarrierID
      const params = {
        'filter[servicePlannings]': this.folio,
        'filter[servicePlanningsCarriers]': this.servicePlanningCarrierID,
        'filter[checks]': this.receptionState.surveillanceCheck,
      }

      this.$store.dispatch('jv/get', ['checkListForms', {
        params: params,
      }])
        .then(response => {
          if (response.jsonApi.json.data.length > 0) {
            const keys = Object.keys(response)
            const checkListForm = response[keys[0]]
            this.checkListFormId = checkListForm.jsonApi.id
          }
          this.showSurveillanceCheck = response.jsonApi.json.data.length > 0
        })
    },
    CloseCheckListModal() {
      this.showSurveillanceCheck = false
      this.$refs['checklist-Form'].hide()
      this.servicePlanningCarrierID = ''
      this.surveillanceForm = {
        inspector_user_id: undefined,
        date: moment(),
        final_hour: moment(),
        comments: '',
      }
    },
    // Set CheckList
    // JSONAPI POST CHECKLISTFORM
    saveCheckListForm() {
      this.$refs['surveillance-Form'].validate(valid => {
        if (valid) {
          // Validar el # de campos contestados
          if (this.surveillanceTotalFields !== Object.keys(this.surveillanceCheckListForm).length) {
            Modal.error({
              title: '¡Ha ocurrido un error!',
              content: 'Debe contestar todos los campos del Check List',
              zIndex: 3000,
            })
            return
          }
          // Create Request
          const request = {
            check_id: this.receptionState.surveillanceCheck,
            service_planning_id: this.folio,
            service_plannings_carrier_id: this.servicePlanningCarrierID,
            inspectors: this.surveillanceForm.inspector_user_id,
            date: this.surveillanceForm.date,
            final_hour: moment(this.surveillanceForm.final_hour).format('HH:mm'),
            comments: this.surveillanceForm.comments,
            answers: this.surveillanceCheckListForm,
          }
          this.surveillanceLoad = true
          api.post('/saveCheckListAnswers', request)
            .then(response => {
              this.CloseCheckListModal()
              this.GetCarriers()
              this.surveillanceLoad = false
              Modal.success({
                title: '¡Actualizado!',
                content: 'Se ha registrado el CheckList exitosamente',
                zIndex: 3000,
              })
            })
            .catch(() => {
              this.surveillanceLoad = false
            })
        } else {
          return false
        }
      })
    },
    saveLabCheckListForm() {
      this.$refs['laboratory-Form'].validate(valid => {
        if (valid) {
          // Create Request
          // Validar el # de campos contestados
          if (this.laboratoryTotalFields !== Object.keys(this.laboratoryCheckListForm).length) {
            Modal.error({
              title: '¡Ha ocurrido un error!',
              content: 'Debe contestar todos los campos del Check List',
              zIndex: 3000,
            })
            return
          }
          const request = {
            check_id: this.receptionState.laboratoryCheck,
            service_planning_id: this.folio,
            service_plannings_carrier_id: this.servicePlanningCarrierLabID,
            inspectors: this.laboratoryForm.inspector_user_id,
            authorizers: this.laboratoryForm.autho_user_id,
            date: this.laboratoryForm.date,
            final_hour: moment(this.laboratoryForm.final_hour).format('HH:mm'),
            comments: this.laboratoryForm.comments,
            answers: this.laboratoryCheckListForm,
          }
          this.laboratoryLoad = true
          api.post('/saveCheckListAnswers', request)
            .then(response => {
              this.laboratoryLoad = false
              this.showLaboratoryCheck = true
              this.CloseCheckListModalLab()
              this.GetCarriers()
              Modal.success({
                title: '¡Actualizado!',
                content: 'Se ha registrado el CheckList exitosamente',
                zIndex: 3000,
              })
            })
            .catch(() => {
              this.laboratoryLoad = false
            })
        } else {
          return false
        }
      })
    },
    // Surveillance
    // Checks de Lab
    async SelectCarrierLab(data) {
      // Set the servicePlanningCarrierID
      this.$refs['checklist-LabForm'].show()
      this.servicePlanningCarrierLabID = data.jsonApi.id
      await this.GET_CHECK(this.receptionState.laboratoryCheck)
      // Verificar si existe el checkListForm correspondiente al servicio y check de lab
      const params = {
        'filter[servicePlannings]': this.folio,
        'filter[servicePlanningsCarriers]': this.servicePlanningCarrierLabID,
        'filter[checks]': this.receptionState.laboratoryCheck,
      }

      await this.$store.dispatch('jv/get', ['checkListForms', {
        params: params,
      }])
        .then(response => {
          if (response.jsonApi.json.data.length > 0) {
            const keys = Object.keys(response)
            const checkListForm = response[keys[0]]
            this.labCheckListFormId = checkListForm.jsonApi.id
          }
          this.showLaboratoryCheck = response.jsonApi.json.data.length > 0
        })
    },
    CloseCheckListModalLab() {
      this.showLaboratoryCheck = false
      this.$refs['checklist-LabForm'].hide()
      this.servicePlanningCarrierLabID = ''
      this.laboratoryForm = {
        inspector_user_id: undefined,
        autho_user_id: undefined,
        date: moment(),
        final_hour: moment(),
        comments: '',
      }
    },
    // Checks de Lab
    // Incidence
    onCancelIncidence() {
      Modal.confirm({
        title: 'El siguiente proceso será cancelado, por lo que el servicio será enviado a los "Rechazados"',
        // content: 'Esta operación es irreversible',
        okText: 'Aceptar',
        okType: 'primary',
        cancelText: 'Cancelar',
        cancelType: 'secondary',
        zIndex: 3000,
        onOk() {
          notification.success({
            message: 'Servicio cancelado',
          })
        },
        onCancel() {
          notification.info({
            message: 'No se ha cancelado el registro',
          })
        },
      })
    },
  },
  async mounted() {
    // Set Initial State
    this.SET_SERVICEID(this.folio)
    this.getServiceAndSetInState(this.service_folio)
    // Get Catalogs Info
    await this.getSurveillanceInspectors()
    // Get Catalogs Info
  },
}
</script>

<style scoped>
.border-w-3 {
  border-width:3px !important;
}
.border-w-5 {
  border-width:5px !important;
}
.card-container {
  background: #f5f5f5;
  overflow: hidden;
  padding: 24px;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
</style>
